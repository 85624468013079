import React from 'react'
import Layout from '../components/Layout/Layout'
import TextSection from '../components/_shared/TextSection/TextSection'
import ServicesSection from '../components/_shared/ServicesSection/ServicesSection'
import ContactFormSection from '../components/_shared/ContactFormSection/ContactFormSection'

const ServicesPage = () => (
  <Layout pageTitle="Usługi" headerImage={2}>
    <div className="container container--page">
      <TextSection contentText="W trosce o zadowolenie naszych klientów oferujemy szeroki wachlarz usług, które zwiększą bezpieczeństwo i komfort pracy na każdym stanowisku. Nieustannie poszerzamy i udoskonalamy naszą ofertę po to, aby odpowiadała zróżnicowanym potrzebom naszych kontrahentów. <br/><br/>Zaufaj doświadczeniu i obszernej wiedzy naszych specjalistów, którzy skrupulatnie przeanalizują potrzeby Twojego przedsiębiorstwa i dobiorą najbardziej optymalne rozwiązania dla zwiększenia bezpieczeństwa i wygody pracy. Sprawdź, co możemy Ci zaoferować." />
      <ServicesSection />
    </div>
    <ContactFormSection />
  </Layout>
)

export default ServicesPage
